'use client';

import { api, trpcClient } from '@streetferret/api-client/client';
import { Toaster } from '@streetferret/ui-web/toaster';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from 'next-themes';
import {
  type Unit,
  UnitPreferenceProvider,
} from '@streetferret/ui-web/typography/unit-preference';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export interface ProvidersProps {
  children: React.ReactNode | React.ReactNode[];
  unit: Unit;
  defaultTheme?: 'dark' | 'light' | 'system';
}
export function Providers({
  children,
  unit,
  defaultTheme = 'system',
}: ProvidersProps) {
  return (
    <ThemeProvider defaultTheme={defaultTheme}>
      <UnitPreferenceProvider unit={unit}>
        <api.Provider queryClient={queryClient} client={trpcClient}>
          <QueryClientProvider client={queryClient}>
            {children}
            <Toaster />
          </QueryClientProvider>
        </api.Provider>
      </UnitPreferenceProvider>
    </ThemeProvider>
  );
}

BigInt.prototype['toJSON'] = function () {
  // const int = Number.parseInt(this.toString());
  return this.toString();
};
